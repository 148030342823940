<template>
    <button 
        class="btn " 
        :style="{backgroundColor: color}"
        @click="onClick()"
        >
        {{text}}
    </button>
</template>

<script>
    export default {
        name:'Button',
        props:{
            color:{
                type:String,
                default:'black'
            },
            text:{
                type:String,
                default:'Add'
            },

        },
        methods:{
            onClick(){
                console.log('OK')
            }
        }
    }
</script>

