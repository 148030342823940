<template>
    <div
     @dblclick="$emit('toggle-reminder',task.id)"
    :class="[task.reminder ? 'reminder' : '',
    'task']"
    >
        <h3>{{ task.text }}
            <div 
                class="i fas fa-times"
                @click="$emit('delete-task',task.id)"
            ></div>
        </h3>
        <p>{{ task.day }}</p>
    </div>
</template>

<script>
    export default {
     name:'Task',
     props:{
         task:Object
     },
     emits:['delete-task','toggle-reminder']   
    }
</script>

<style scoped>
.fas {
  color: red;
}
.task {
  background: #f4f4f48f;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.task.reminder {
  border-left: 5px solid green;
}
.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>