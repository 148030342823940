<template>
  <div class="container">
    <Header 
    @show-add-task-form="showAddTaskForm"
    title="Task Tracker" :showAddTask="showAddTask" />
    <AddTask v-show="showAddTask"  @add-task="addNewTask" />
    <Tasks
      @toggle-reminder="toggleReminder" 
      @delete-task="deleteTask" 
      :tasks="tasks" 
    />
  </div>
  
</template>

<script>

import Header from './components/Header.vue'
import Tasks from './components/Tasks.vue'
import AddTask from './components/AddTask.vue'

  export default {
    name: 'App',
    data(){
      return{
        tasks:[],
        showAddTask:false
      }
    },
    methods:{
      showAddTaskForm(value){
        this.showAddTask=!this.showAddTask
      },
      deleteTask(id){
        if(confirm('Are you sure?')){
          this.tasks = this.tasks.filter((task)=>task.id !== id )
        }
      },
      toggleReminder(id){
        this.tasks = this.tasks.map((task) => task.id === id ? {...task,reminder:!task.reminder} : task)
      },
      addNewTask(newTask){
        this.tasks = [...this.tasks,newTask]
      }
    },
    components: {
      Header,
      Tasks,
      AddTask
    },
    created(){
      this.tasks=[
        {
          id:1,
          text:'Meeting Ian',
          day:'March 3rd at 1:30pm',
          reminder:true
        },
        {
          id:2,
          text:'Meeting Victor',
          day:'March 3rd at 1:30pm',
          reminder:true
        },
        {
          id:3,
          text:'Breakfast',
          day:'March 3rd at 9am',
          reminder:false
        }
      ]
    }
  }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Poppins', sans-serif;
}
.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}
.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
.btn:focus {
  outline: none;
}
.btn:active {
  transform: scale(0.98);
}
.btn-block {
  display: block;
  width: 100%;
}
</style>
